.picker--time {
    max-width: auto;
    min-width: auto;
    width: 50%;
    .picker__frame {
        min-width: auto;
        max-width: 50%;
    }
    .picker__box {
        padding: 20px;
        background: #fff;
    }
}
.picker__right {
    right: 0 !important;
}