.v-app {
  width: 100%;
  min-height: 100vh;
  background: url(../../../images/bg-green.jpg?v=10) left center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.v-appWrapper-mixed, .v-appWrapper-mm {
  padding-bottom: 70px;
  padding-top: 60px;
}
.v-appWrapper-calendar {
  padding-bottom: 0px;
  padding-top: 0px;
}


@include media-breakpoint-up(md) {
  .v-appWrapper-mixed {
    position: absolute;
    top: 110px;
    left: 110px;
    right: 30px;
    bottom: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-appWrapper-mm {
    position: absolute;
    top: 58px;
    left: 80px;
    right: 0px;
    bottom: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-appWrapper-calendar {
    top: 0 !important;
    left: 80px !important;
    right: 0 !important;
  }
}

.v-appLogin {
  height: 100vh;
}
.v-animated {
  transition: .3s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.v-desktop {
  display: none !important;
}
@include media-breakpoint-up(md) {
  .v-desktop {
    display: flex !important;
  }
  .btn.v-desktop {
    display: inline-block !important;
  }
  .container-customer {
    width: 1000px;
  }
}

.app-customer {
  min-height: 100vh;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
cite {
  word-wrap: break-word;
}
