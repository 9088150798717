.introjs-showElement {
    &:after {
        z-index: 9999999 !important;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: ' ';
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.1);
    }
}
.introjs-helperLayer {
    background-color: rgba(255,255,255,.1);
}