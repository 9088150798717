.btn {
    @include transition(.5s);
    //font-size: 1.25rem;
    //font-weight: 200;
}
.btn-sm {
    font-size: 0.875rem;
}
.btn-rounded {
    border-radius: 1.875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.btn-rounded.dropdown-toggle-split {
    padding-left: 0.5625rem;
}
.btn-dark {
    background: $gray-800;
    border-radius: 0;
}
