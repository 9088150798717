.bookingWidget {
    &-header {
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 999;
    }
    &-body {
        padding-top: 80px;
        overflow-y: scroll;
        flex: 1 1 0%;
        position: relative;
        height: 100%;
        width: 100%;
    }
    &-main {
        height: auto;
    }
}
#componentBookingWidget {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
}