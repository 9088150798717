.appLoader {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.4);
    z-index: 2147483646;
    
    opacity: 0;
    visibility: hidden;
    &-wave {
        stroke-dasharray: 800;
        stroke-dashoffset: 800; 
    }
}
.appLoader-active {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear opacity .4s linear;
    .appLoader-wave {
        animation: loader-wave 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite forwards;
    }
}