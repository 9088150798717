.nav-pills-big {
  .nav-link {
    @include border-radius(2.0625rem);
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 200;
    padding: 0.5rem 1.87rem 0.7rem 1.87rem;
    @include transition(.5s);
    &.active,
    .show > & {
      color: $nav-pills-link-active-color;
      background-color: $nav-pills-link-active-bg;
    }
    &:hover {
      color: $blue;
    }
  }
}
.nav-item-hidden {
  display: none;
}
.nav-pills {
  .nav-link {
    padding: 0.5rem 1.87rem 0.7rem 1.87rem;
  }
}