
/*
.form-control {
  border: none;
  border-bottom: 1px solid $blue;
  border-radius: 0;
  font-weight: 300;
  &:focus {
    box-shadow: none;
  }
}
*/
.form-groupped {
  .input-group-text {
    background: transparent;
    border-color: transparent;
  }
  .custom-select {
    width: 100%;
    border-radius: 0;
    border: none;
    color: $gray-800;
    padding-left: 0.9375rem;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
  .form-group {
    border: 1px solid $gray-300;
    margin: 0;
    background: $white;
    position: relative;
    @include transition(0.5s);
    &-active {
      border-color: theme-color("info");
      background: $cyan-light;
    }

    &-is-invalid {
      border-color: $form-feedback-invalid-color;
    }
    &-is-valid {
      border-color: $form-feedback-valid-color;
    }
    &-disabled {
      border-color: $gray-300;
      background: theme-color("light");
      &:after {
        position: absolute;
        content: " ";
        background: rgba($gray-600, 0.2);
        width: 100%;
        height: 100%;
        left: 1px;
        top: 1px;
        bottom: 1px;
        right: 1px;
      }
    }
    &-required:after {
      color: theme-color("danger");
      content: "*";
      font-family: arial;
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 9px;
    }
  }
  .form-control {
    border: none;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
    color: $gray-800;
    &:focus {
      box-shadow: none;
    }
  }
  
  .col-form-legend {
    font-size: 1rem;
    color: $blue;
    font-weight: bold;
    padding-top: 1rem;
  }
  .form-check {
    padding: 0 0.9375rem;
  }
}
.col-form-label {
  color: $gray-600;
  font-weight: 200;
  font-size: .8rem;
  text-transform: uppercase;
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
}
.form-group {
  position: relative;
  @include transition(0.5s);
    &-active {
      .form-control {
        border-color: theme-color("info");
      }
      
    }

    &-is-invalid {
      .form-control {
        border-color: $form-feedback-invalid-color;
      }
    }
    &-is-valid {
      .form-control {
        border-color: $form-feedback-valid-color;
      }
    }
    &-disabled {
      .form-control {
        border-color: $gray-300;
        background: theme-color("light");
      }
      &:after {
        position: absolute;
        content: " ";
        background: rgba($gray-600, 0.2);
        width: 100%;
        height: 100%;
        left: 1px;
        top: 1px;
        bottom: 1px;
        right: 1px;
      }
    }
  &-required:after {
    color: theme-color("danger");
    content: "*";
    font-family: arial;
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 9px;
  }
}
//Office select form

[data-office-profile="assign-wrap"],
[data-office-profile="results-wrap"] {
  display: none;
}

//Select bug
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.filter {

    padding-left: 1rem;
    padding-right: 1rem;
}

//Filter styles
.filter {
  .selectize-control.single .selectize-input {
    min-width: 300px;
  }
}
//Datepicker readonly
.datepicker[readonly] {
  background: #fff;
}

body > .select2-container--default {
  z-index: 99999;
}
.select2-container--default {
  width: 100%;

  .select2-selection--single {
    background-color: transparent;

    border: 0;
    border-radius: 0;
    height: 42px;
    outline: 0;

    // @include gradient-vertical($selection-bg-top-color, $selection-bg-bottom-color, 50%, 100%);

    &:focus {
      //border: 1px solid theme-color("primary");
      border: 0;
    }

    .select2-selection__rendered {
      color: $gray-800;
      padding: 0.625rem 0.625rem;
      font-size: 1rem;
      line-height: 1.25;
      // line-height: 28px;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 0.625rem;
    }

    .select2-selection__placeholder {
      color: #999;
    }

    .select2-selection__arrow {
      background-color: transparent;

      border: none;
      //border-left: 1px solid theme-color("primary");
      border-radius: 0;

      height: 100%;

      position: absolute;

      top: 0px;
      right: 1px;

      width: 20px;

      // @include gradient-vertical(#eeeeee, #cccccc, 50%, 100%);

      b {
        border-color: theme-color("primary") transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;

        height: 0;
        left: 50%;

        margin-left: -0.25rem;
        margin-top: -0.125rem;

        position: absolute;

        top: 50%;
        width: 0;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      //border: 1px solid theme-color("primary");
      border: none;
      .select2-selection__arrow {
        background: transparent;

        border: none;

        b {
          border-color: transparent transparent theme-color("primary")
            transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }

    &.select2-container--above {
      .select2-selection--single {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        // @include gradient-vertical($selection-opened-bg-bottom-color, $selection-opened-bg-top-color, 0%, 50%);
      }
    }

    &.select2-container--below {
      .select2-selection--single {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        // @include gradient-vertical($selection-opened-bg-top-color, $selection-opened-bg-bottom-color, 50%, 100%);
      }
    }
  }
}

.select2-container--below {
  width: 100%;
}
.select2-container--default .select2-selection--multiple {
  width: 100%;
  border-color: theme-color("primary");
  border-radius: 0;
}
.input-group .select2-container--below, .input-group .select2-container--default {
  position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
    width: auto;
}
.select2-container--default .searchSelect .select2-selection__arrow b {
  border: none;
  width: 15px;
  height: 15px;
  margin-left: -.75rem;
  margin-top: -.75rem;
  font-size: 15px;
  color: #000;
  &:after {
    font-family: 'Pe-icon-7-stroke';
    content: '\e618';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
//Bug fix for select2 in collapse or tabs https://github.com/select2/select2/issues/4220



#filter-more {
  .select2-selection{
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
}
body > .select2-container--open {
  width: auto;
}
.form-groupped .form-group .input-group-special .select2 {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1% !important;
  min-width: 0;
  margin-bottom: 0;
}

.form-groupped .form-group .select2 {
  width: 100% !important;
}

//app prescription submit
.appPrescriptionSubmit-wrapper, .appOrderSubmit-wrapper {
  margin-bottom: 100px;
}
.appPrescriptionSubmit, .appOrderSubmit {
  position: fixed; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  z-index: 999;
}

.appWrapper-mobile {
  .appPrescriptionSubmit, .appOrderSubmit {
    bottom: auto;
    top: 0;
  }
  .appPrescriptionSubmit-wrapper, .appOrderSubmit-wrapper {
    margin-bottom: 0;
  }
}
.filter .custom-checkbox {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: $blue;
}