.v-patient {
    display: flex;
    align-items: center;
    width: 100%;
    &Image {
        padding-right: 10px;
    }
    &Body {
        width: 100%;
    }
    &Title {
        color: $purple;
        font-size: 20px;
        font-weight: 700;
    }
    &Search {
        $calc: calc(50% + 40px);
        position: fixed;
        top: 50%;
        left: $calc;
        transform: translate(-50%,-50%);
        width: 40%;
        @extend .card;
        &-title {
            @extend .card-title;
        } 
        &-header {
            @extend .card-header;
        }
        &-body {
            @extend .card-body;
            padding: 60px;
            .form-control {
                font-size: 25px;
            }
        }
        &-form {
            position: relative;
        }
        &-input {
            @extend .form-control;
            text-align: center;
        }
        &-button {
            position: absolute;
            @extend .btn;
            @extend .btn-link;
            padding: 0;
            right: 0;
            top: 0;
            font-size: 30px;
        }
    }
}
