
    .fc-event {
        box-shadow: 0 4px 20px rgba($purple,.14), 0 7px 10px -5px rgba($blue,.4);
        background: $purple;
        border: none;
        color: #fff;
        transition: .5s;
        &:hover {
            cursor: pointer;
            background: $blue;
        }
    }
    .event-colored {
        position: relative;
        padding-top: 20px;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            content: ' ';
            width: 100%;
            height: 15px;
            z-index: 1;
        }
    }
    .event-colored .fc-time {
        background: $gray-800;
        color: #fff;
    }
    .fc-time-grid-event.event-colored.event-free {
        &:after {
            background-color: darken($green, 20%);
        }
    }
    .fc-time-grid-event.event-colored.event-half {
        &:after {
            background-color: $orange;
        }
    }
    .fc-time-grid-event.event-colored.event-full {
        &:after {
            background-color: $red;
        }
    }
    .fc-list-item {
        &:hover {
            cursor: pointer;
        }
    }
    .fc-list-item.event-colored.event-free {
        background: $green;
        &:hover td{
            background: darken($green, 20%);
        }
    }
    .fc-list-item.event-colored.event-half {
        background: $orange;
        &:hover td{
            background: darken($orange, 20%);
        }
    }
    .fc-list-item.event-colored.event-full {
        background: $red;
        &:hover td{
            background: darken($red, 20%);
        }
    }
    .badge-full {
        background-color: $red;
        color: #fff;
    }
    .badge-half {
        background-color: $orange;
        color: #fff;
    }
    .badge-free {
        background-color: darken($green, 20%);
        color: #fff;
    }
    .fc-time-grid-event.event-colored .fc-time {
        font-size: 1rem;
        display: block;
        //text-align: center;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0;
        span {
            border-radius: 0;
            display: block;
            white-space: normal;
            
        }
    }
    .fc-time-grid-event.event-colored .fc-title {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        span {
            border-radius: 0;
            display: block;
            margin-bottom: 2px;
            white-space: normal;
        }
    }
    .fc-time-grid-event.event-colored .fc-content {
        height: 100%;
    }
    .fc-time-grid-event.event-colored .fc-time {
        background: transparent;
    }
    .fc-time-grid-event.event-colored {
        box-shadow: 0 0 10px rgba($green, .4);
    }



    //CSS GRID calendar
$title-height: 3em;
$days-height: 3em;
$time-width: 3em;
$time-height: 3em;
$grid-color: #dadce0;
$calendar-template: $time-width 10px repeat(7, 1fr);
$current-time-color: #ea4335;

* {
  box-sizing: border-box;
}

body {
  background: #fff;
}

.calendar-container {
  width: 100%;
  background: #fff;
  position: relative;
  height: 100%;
  //display: grid;
  //grid-template-rows: $title-height $days-height auto;
  //position: absolute;
}

.title {
  background: #217346;
  text-align: center;
  display: grid;
  place-content: center;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
}

.days {
  background: #f3f2f1;
  display: grid;
  place-content: center;
  text-align: center;
  grid-template-columns: $calendar-template;
  position: sticky;
  top: 85px;

  z-index: 9;
  border-bottom: 2px solid $grid-color;
}
.days--one {
  grid-template-columns: $time-width 10px repeat(1, 1fr);
}
.day {
  border-left: 1px solid $grid-color;
  padding: 10px 0 ;
  font-size: 80%;
}

.content {
  display: grid;
  grid-template-columns: $calendar-template;
  //grid-template-rows: repeat(24, $time-height);
}
.content--one {
  grid-template-columns: $time-width 10px repeat(1, 1fr);
}
.time {
  grid-column: 1;
  text-align: right;
  align-self: start;
  font-size: 80%;
  position: relative;
  bottom: -1ex;
  color: #70757a;
  padding-right: 2px;
}

.col {
  border-right: 1px solid $grid-color;
  grid-row: 1 / span 24;
  grid-column: span 1;
}
.first {
    border-left: 1px solid $grid-color;
}

.filler-col {
  grid-row: 1 / -1;
  grid-column: 2;
  border-right: 1px solid $grid-color;
}

.row {
  grid-column: 2 / -1;
  border-bottom: 1px solid $grid-color;
}

.event {
  border-radius: 5px;
  padding: 5px;
  margin: 3px;
  font-size: 80%;
  margin-bottom: 5px;
  position: relative;
  z-index: 7;
  display: block;
  .badge {
    font-size: .8rem;
  }
}

.weekend {
  background-color: #f1f3f4;
}

.calendar2 {
  background-color: lighten($success, 20%);
  border-color: #bcc3e5;
}
.calendar4 {
  background-color: lighten($danger, 20%);
  border-color: #bcc3e5;
}

.calendar1 {
  background-color: #b3e1f7;
  border-color: #81cdf2;
}
.calendar3 {
    background-color: $gray-700;
    border-color: $gray-800;
    position: sticky;
    z-index: 9;
    top: 130px;
    color: #fff;
    font-size: 60%;
    transition: .5s;
    &:hover, &:active, &:focus {
      background: $gray-800;
      text-decoration: none;
      color: #fff;
    }
  }

.current-time {
  grid-column: 7;
  grid-row: 10;
  border-top: 2px solid $current-time-color;
  position: relative;
  top: calc(50% - 1px);
}

.circle {
  width: 12px;
  height: 12px;
  border: 1px solid $current-time-color;
  border-radius: 50%;
  background: $current-time-color;
  position: relative;
  top: -6px;
  left: -6px;
}

.current {
  font-weight: bold;
}
.fc-time-grid-event {
  padding: 10px;
  background: #b3e1f7 !important;
  color: #254e70 !important;
}
.fc-time-grid-event.fc-short .fc-time:before {
  content: '';
}
.fc-time-grid-event .fc-title {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.fc-time-grid-event.fc-short  {
  padding: 1px;
  .fc-title {
    flex-flow: row;
    span {
      margin: 0 2px;
    }
  }
}

.md-custom-event-cont {
    font-size: 12px;
    font-weight: 600;
    height: 100%;
    border-left: 5px solid #64cad4;
    background: #64cad4;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(#000, .3);
    border-radius: 0 10px 10px 0;
}

.md-custom-event-wrapper {
  background: rgba(255, 255, 255, .5);
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  transition: background .15s ease-in-out;
}
.md-custom-event-category {
  display: block;
  color: #eee;
  font-size: 10px;
  border-radius: 10px;
  background:#64cad4;
  padding: 2px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.md-custom-event-details {
    padding: 0;
}
.md-custom-event-title {
  //padding-top: 5px;
  color: initial;
  font-size: 10px;
}
.md-custom-event-time {
  font-size: 10px;
  color: #666;
  //padding-top: 3px;
}
.cal-header-nav {
    width: 200px;
}
.cal-header-picker {
  flex: 1 0 auto;
}
.mbsc-schedule-item {
  transition: .5;
}
.mbsc-schedule-item:hover {
  background: rgba(#64cad4, .4);
  cursor: pointer;
}
.md-custom-event-wrapper {
  overflow: hidden;
}
.calendarTabs {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 9990;
  display: none;
}
@include media-breakpoint-up(md) {
  .calendarTabs {
    display: block;
    left: 80px;
    width: calc(100% - 80px);
  }
}
.calendar-button {
  border: 1px solid #ccc !important;
  margin-right: .4rem !important;
}
#calendar {
  margin-left: 5px;
  margin-right: 5px;
}
@include media-breakpoint-up(md) {
  #calendar {
    margin-left: 30px;
    margin-right: 30px;
  }
}
.mbsc-calendar-wrapper {
  position: relative;
  z-index: 20;
}
#services-top-bar {
  display: none;
}
@include media-breakpoint-up(md) {
  #services-top-bar {
    display: block;
  }
}
.topbar-search {
  position: relative;
  width: 100%;
}
@include media-breakpoint-up(md) { 
  .topbar-search {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.mbsc-schedule-events.mbsc-ltr {
  margin-right: 1.3rem;
}
.mbsc-schedule-color-text {
  color: #000;
}
