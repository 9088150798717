.v-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 $breadcrumb-padding-x;
    margin-bottom: 0;
    align-items: center;
    &Item {
        display: list-item;
        font-weight: 700;
        font-size: 14px;
        + .v-breadcrumbItem {
            padding-left: 30px;
            
            position: relative;
        
            &::before {
                display: block; // Suppress underlining of the separator in modern browsers
                height: 100%;
                width: 1px;
                left: 15px;
                position: absolute;
                border-left: 1px solid $gray-600;
                content: '';
            }
          }
        &.active {
            color: $breadcrumb-active-color;
          }
    }
}