// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Start with assigning color names to specific hex values.

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$blue: #254E70;
$blue-light: #eaeef9;
$indigo: #5368a3;
$indigo-light: #edeff6;
$purple: #51811b;
$purple-light: #f0e2f9;
$pink: #D43C84;
$red: #dc3545;
$red-light: #fdf4f5;
$orange: #fd7e14;
$yellow: #d8860b;
$green: #8DB75D;
$green-light: #ecf9f1;
$teal: #F0F6FF;
$teal–light: #e7fbf5;
$cyan: #2061B5;
$cyan-light: #e7f7fa;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $blue,
  blue: $blue,
  cyan: $cyan,
  purple: $purple,
  secondary: $teal,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $pink,
  light: $gray-100,
  dark: $gray-800
);

$enable-gradients:          true;

// Cards

$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;
$card-border-width: 0px;
$card-border-radius: 5px;
$card-bg: $white;
$card-cap-bg: transparent;
$card-img-overlay-padding: 1.25rem;

$card-columns-count: 2;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Links
//
// Style anchor elements.

$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: $cyan;
$link-hover-decoration: none;

//Fonts
$font-family-sans-serif: "Ubuntu", "Helvetica Neue", Arial,
  sans-serif;

//Table
$table-border-color: theme-color("primary");

//Forms
$input-bg: transparent;
//$input-color: $blue;
//$input-group-addon-bg: transparent;
//$input-group-addon-border-color: transparent;


//Breadcrumbs
$breadcrumb-bg:                 $white;
$breadcrumb-divider-color:      $gray-600;
$breadcrumb-active-color:       $gray-600;

//Tooltip
$tooltip-bg:  theme-color("primary");
$zindex-tooltip:                    9998;

//Modal
$modal-transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1);
$modal-lg:                          50%;
$modal-md:                          70%;
$modal-sm:                          100%;
$modal-dialog-margin: 0;
$modal-dialog-margin-y-sm-up: 0;
$modal-backdrop-bg: rgba(theme-color("primary"),.4);
$modal-content-border-width: 0;
$modal-content-border-color: transparent;
$modal-content-box-shadow-sm-up: 0 0 8px rgba($blue,.3);

//Navs
$nav-pills-border-radius: 0;
$nav-pills-link-active-bg: #fff;
$nav-pills-link-active-color: $purple;

$nav-tabs-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-bg: $purple;
$nav-tabs-link-active-color: $white;
//Popover
$zindex-popover:                    9999;


