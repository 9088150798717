.card {
	box-shadow: 0 10px 30px rgba($blue,.3);
	width: 100%;
}
.card-selected {
	box-shadow: 0 0 10px rgba($green, 0.5);
	background: lighten($green, 40%);
	.card-footer {
		background: lighten($green, 40%);
	}
}
.card-title {
	font-size: 1.2rem;
	margin-bottom: 0;
	font-weight: 800;
	color: $purple;
}
.card.text-white {
	.card-title {
		color: #fff;
	}
}
.card.text-white {
	.card-body, .card-header {
		a,.btn {
			color: #fff;
			&:hover {
				color: #fff;
			}
		}
	}
	.pagination {
		margin-bottom: 0;
		.page-link {
			background-color: $cyan;
			border-color: $cyan;
		}
		.page-item.active {
			.page-link {
				background-color: $purple;
				border-color: $purple;
			}
		}
	}
}
.card-footer {
	background: #fff !important;
}
.card-header {
	padding-bottom: 0;
}
.card-title-big {
	font-size: 1.5625rem;
	margin-bottom: 0;
	font-weight: 400;
	line-height: 1;
	color: theme-color('primary');
	text-transform: uppercase;
}
.card-title-medium {
	font-size: 1.25rem;
	margin-bottom: 0;
	font-weight: 400;
	line-height: 1;
	color: theme-color('primary');
	text-transform: uppercase;
}
.card-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.btn {
		padding-top: 0;
		padding-bottom: 0;
	}
}
//Tabs
.card-tabs-special {
	position: relative;
	margin-top: -2px;
	.card-body {
		position: relative;
		background: #fff;
		border-radius:  0 5px 5px 5px ;
		z-index: 4;
	}
}

.card-header-tabs-special {
	border-bottom: 0;
	flex-direction: column;
	.nav-item {
		margin-bottom: 0;
	}
	.nav-link {
		border: none;
		font-size: 1rem;
		font-weight: 700;
		padding: 0.9375rem 1.875rem;
		border-radius: $nav-tabs-border-radius 0 0 0;

		@include hover-focus {
			border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
		}

		&.disabled {
			color: $nav-link-disabled-color;
			background-color: transparent;
			border-color: transparent;
		}
	}

	.dropdown-menu {
		// Make dropdown border overlap tab border
		margin-top: -$nav-tabs-border-width;
		// Remove the top rounded corners here since there is a hard edge above the menu
		@include border-top-radius(0);
	}
}
@include media-breakpoint-up(lg) {
	.card-header-tabs-special {
		flex-direction: row;
			.nav-link.active,
	.nav-item.show .nav-link {
		color: $nav-tabs-link-active-color;
		background-color: $nav-tabs-link-active-bg;
		border-color: $nav-tabs-link-active-border-color $nav-tabs-link-active-border-color $nav-tabs-link-active-bg;
		position: relative;
		z-index: 3;
		margin-right: 1.875rem;
		box-shadow: 0 0 8px rgba($blue, 0.3);
		&:after {
			position: absolute;
			content: ' ';
			right: -30px;
			top: 0;
			z-index: 2;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 60px 0 0 30px;
			border-color: transparent transparent transparent #ffffff;
		}
	}
	}
}