.v-sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 10px 30px rgba($blue,.3);
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    z-index: 9080;
    &Logo {
        img {
            height: 30px;
        }
    }
    &Nav {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        display: flex;
        &-item {
            padding: 0;
            list-style: 0;
            &> a {
                position: relative;
                font-size: 20px;
            }
        }
    }
    &Account {
        &-image {
            display: none;
        }
    }
}
@include media-breakpoint-up(md) {
.v-sidebar {
    position: fixed;
    width: 80px;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    height: 100vh;
    flex-direction: column;
    z-index: 9980;
    &Logo {
        padding-top: 10px;
        img {
            height: auto;
        }
    }
    &Nav {
        list-style: none;
        padding: 0;
        display: block;
        &-divider {
            padding: 0;
            list-style: 0;
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;
        }
        &-item {
            margin-bottom: 10px;
            &> a {
                font-size: 25px;
                color: $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                transition: .5s;
                &:hover,&:focus {
                    box-shadow: 0 0px 20px rgba($blue,.3);
                }
            }
            &.active {
                &> a {
                    color: $pink;
                    box-shadow: 0 0px 20px rgba($purple,.3);
                    &:hover {
                        box-shadow: 0 0px 20px rgba($purple,.5);
                    }
                }
            }
        }
    }
    &Account {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        &-image {
            padding-bottom: 10px;
            display: block;
            img {
                width: 50px;
            }

        }
        .nav-link {
            padding: 0 5px;
        }
    }
}
}
