.loadAnimation {
    .v-body {
        animation-timing-function: cubic-bezier(.25, .25, .4, 1.6);
        animation-duration: .5s;
        animation-fill-mode: forwards;
        transition: visibility 0s linear;
    }
    &-hide {
        .v-navigation {
            display: none;
        }
        .v-body {
            animation-name: load-hide;
            visibility: hidden;
        }
    }
    &-show {
        .v-navigation {
            display: flex;
        }
        .v-body {
            animation-name: load-show;
            visibility: visible;
        }
    }
}