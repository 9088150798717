@keyframes patient-show {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
}
@keyframes load-show {
    from {
      transform: translateY(20px) scale(.95);
      opacity: 0;
    }
    to {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
}
@keyframes load-hide {
    from {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    to {
      transform: translateY(20px) scale(.95);
      opacity: 0;
    }
}
@keyframes user-show {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
}
@keyframes cross-show {
    from {
      transform: scale(.5) rotate(115deg);
      opacity: 0;
    }
    to {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
}

  @keyframes alert-show {
    from {
      transform: translateX(300px);
    }
    to {
      transform: translateX(0);
    }
}
@keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
}
@keyframes loader-wave {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  @keyframes fadeInCircle {
    0% {
        opacity: 0;
        width: 74px
    }
    100% {
        opacity: 1;
        width: 74px
    }
}
@-webkit-keyframes fadeInCircle {
    0% {
        opacity: 0;
        width: 74px
    }
    100% {
        opacity: 1;
        width: 74px
    }
}
@keyframes resizeCircle {
    0% {
        width: 74px
    }
    100% {
        width: auto
    }
}
@-webkit-keyframes resizeCircle {
    0% {
        width: 74px
    }
    100% {
        width: auto
    }
}
@keyframes fadeIn {
  0% {
      opacity: 0
  }
  100% {
      opacity: 1
  }
}
@-webkit-keyframes fadeIn {
  0% {
      opacity: 0
  }
  100% {
      opacity: 1
  }
}
@-webkit-keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
  }
  
@keyframes placeHolderShimmer {
0% {
    background-position: -468px 0
}
100% {
    background-position: 468px 0
}
}