.order {
    padding: 2rem;
    background: #fff;
    box-shadow: 0 0 20px rgba($black, .2);
    //border: 1px solid rgba($purple, .3);
    border-radius: 0 5px 5px 5px;
    &-title {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: .3rem;
    }
    &-label {
        font-size: .9rem;
        color: $gray-600;
        margin-bottom: 1rem;
    }
    &-time {
        display: flex;
        //padding: 2rem 0;
        border-top: 1px solid $gray-400;
        border-bottom: 1px solid $gray-400;
        
        &-date {
            font-size: .8rem;
            color: $purple;
            margin-bottom: 0;
        }
        &-hour {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
    &-time-small {
        padding: .5rem 0;
        .order-time-date {
            font-size: .7rem;
        }   
        .order-time-hour {
            font-size: 1.2rem;
        }
    }
    &-from {
        width: 50%;
    }
    &-to {
        padding-left: 1rem;
        width: 50%;
        border-left: 1px solid $gray-400;
    }
    &-patient {
        //padding: 2rem 0;
        //border-bottom: 1px solid $gray-400;
    }
    &-customer {
        font-weight: bold;
        font-size: 1.4rem;
        margin-bottom: 0;
    }
    &-email, &-phone {
        margin-bottom: 0;
        color: $gray-600;
    }
    &-status {
        //padding: 2rem 0;
        //border-bottom: 1px solid $gray-400;
    }
    &-text {
        margin-bottom: 0;
        color: $gray-600;
        span {
            color: #000;
        }
    }
    &-right {
        background: #fff;
        border: none;
        box-shadow: none;
    }
    
}
.worklist-item-active {
    box-shadow: 0 0 20px rgba($blue, 0.4);
    background: lighten($blue, 20%);
    color: #fff;
    .card-title {
        color: #fff;
    }
    .btn {
        color: #fff;
        border-color: #fff;
    }
}
.booking-selected-times {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: .5rem 1rem;
}
.order-part {
    position: relative;
    width: 100%;
    .card-title-big {
        text-transform: none;
        font-size: .9rem;
        padding-left: 3rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;;
        color: #000;
        display: flex;
        align-items: center;
    }
    .badge-pill {
        position: absolute;
        left: 0;
        z-index: 2;
        top: 10px;
        font-size: 1.2rem;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
    }
}
.worklist-table {
    //height: calc(100vh - 250px);
    position: relative;
    //overflow-y: auto;
    border-radius: 5px;
    .card-title {
        font-size: 1rem;
    }
}
@include media-breakpoint-up(lg) {
    .order {
        &-title {
            font-size: 1.5rem;
        }
        &-date {
            font-size: 1rem;
        }
        &-time {
            margin-top: .3rem;
            &-hour {
                font-size: 2rem;
            }
        }
    }
    .order-part {
        border-bottom: 1px solid $purple;
        padding-bottom: 3rem;
        padding-top: 1rem;
        position: relative;
        padding-left: 3rem;
        &:after {
            height: 100%;
            width: 1px;
            left: 20px;
            top: 0;
            content: ' ';
            z-index: 1;
            position: absolute;
            background: $purple;
    
        }
        .card-title-big {
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 0;
            font-size: 1.2rem;
            color: #000;
            margin-bottom: 2rem !important;
        }
    }
}
.rdrMonth {
    width: auto !important;
}
