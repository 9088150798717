.mm-list-contact {
  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    background: rgba(235, 229, 255, 1);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: $purple;
    &-big {
      width: 50px;
      height: 50px;
      font-size: 1rem;
    }
  }
  &.active {
    background: rgba($purple, 0.9);
    color: #fff;
    .card-title {
      color: #fff;
    }
  }
  &:hover {
    background: lighten($purple, 60%);
    color: $purple;
    transition: 0.5s;
  }
  &-header {
    background: rgba(#fff, 0.3);
  }
}
.mm {
  &-button-thread {
    margin-left: 30px;
    border-radius: 0;
    width: 83%;
  }
  &-button-back {
    position: fixed;
    left: 5px;
    top: 10px;
    z-index: 9900;
  }
  &-tabs {
    background: rgb(223, 241, 203);
    background: -webkit-linear-gradient(
      left,
      rgba(223, 241, 203, 1) 0%,
      rgba(187, 212, 158, 1) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(223, 241, 203, 1) 0%,
      rgba(187, 212, 158, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(223, 241, 203, 1) 0%,
      rgba(187, 212, 158, 1) 100%
    );
    box-shadow: none;
    display: none;
    position: fixed;
    left: 0;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    height: 100%;
    &-active {
      display: flex;
    }
  }
  &-container {
    background: rgba(#fff, 0.4);
    //padding: 2rem;
  }
  &-thread {
    background: rgba(#fff, 1);
    padding-bottom: 2rem;
  }
  &-thread-detail {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    &-card {
      background: rgb(134, 73, 164);
      background: -webkit-linear-gradient(
        left,
        rgba(134, 73, 164, 1) 0%,
        rgba(109, 157, 255, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(134, 73, 164, 1) 0%,
        rgba(109, 157, 255, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(134, 73, 164, 1) 0%,
        rgba(109, 157, 255, 1) 100%
      );
      border-radius: 30px;
      .card-header {
        border-radius: 30px 30px 0 0;
        position: relative;
      }
      .card-body {
        border-radius: 0px 0px 30px 30px;
      }
      .card-title {
        color: #fff;
      }
    }
  }
  &-compose-new {
    //background: #F0F6FF;
    //margin: 2rem;
    border-radius: 0 !important;
  }
  &-compose-reply {
    border-radius: 25px;
    margin-top: 2rem;
    background: #f0f6ff;
    .card-footer {
      border-radius: 0 0 25px 25px;
      background: #f0f6ff !important;
    }
  }
  &-compose-concept-buttton {
    display: none;
    border-radius: 0;
  }

  &-message {
    position: relative;
    border-radius: 25px;
    box-shadow: none;
    &-wrap {
      margin-top: 3rem;
      &-right {
        justify-content: flex-end;
      }
      &-right {
        .mm-list-contact-circle {
          color: #fff;
          background: rgb(75, 132, 252);
        }
      }
    }
    &-container {
      width: 70%;
    }
    .card-header {
      border-radius: 25px 25px 0 0;
    }
    .card-body {
      border-radius: 0 0 25px 25px;
    }
    &-right {
      margin-right: 10px;
      background: rgb(130, 186, 254);
      background: linear-gradient(
        90deg,
        rgba(130, 186, 254, 1) 0%,
        rgba(75, 132, 252, 1) 100%
      );
      color: #fff;
      &:after {
        left: 100%;
        top: 20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-left-color: #4b84fc;
        border-width: 8px;
        //margin-top: -8px;
      }
    }
    &-left {
      margin-left: 10px;
      background: rgb(246, 243, 252);
      background: -webkit-linear-gradient(
        left,
        rgba(246, 243, 252, 1) 0%,
        rgba(235, 229, 255, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(246, 243, 252, 1) 0%,
        rgba(235, 229, 255, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(246, 243, 252, 1) 0%,
        rgba(235, 229, 255, 1) 100%
      );

      &:after {
        right: 100%;
        top: 20px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-right-color: #f6f3fc;
        border-width: 8px;
        //margin-top: -8px;
      }
    }
  }

  &-nav-link {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    color: $purple;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
    transition: 0.5s;
    border-radius: 0 !important;
    &.active {
      background: #8db75d !important;
      color: #fff;
    }
  }
  &-nav-item {
    position: relative;
  }
  &-nav-bubble {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    z-index: 9700;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -15px;
    top: 3px;
    background: $pink;
    box-shadow: 0 0px 5px rgba($pink,.8);
    color: #fff;
    font-size: 0.7rem;
    &-sidebar {
      left: auto;
      transform: translate(0%, -50%);
      right: 5px;
      top: 50%;
    }
    &-thread {
      left: -10px;
      transform: translate(0%, -50%);
      top: 50%;
    }
  }
  &-recipient {
    &-item {
      border-radius: 25px;
    }
    &-button {
      padding: .375rem .75rem !important;
    }
    &-columns {
      column-count: 3;
    }
  }
}

#wrapCompose {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  max-height: 100%;
  overflow-y: scroll;
  box-shadow: 0 0px 50px rgba($purple, 0.8);
  .mm-compose-reply {
    margin-top: 0;
    border-radius: 0;
    .card-footer {
      border-radius: 0;
    }
  }
}
@include media-breakpoint-up(md) {
  .mm {
    &-button-thread {
      width: auto;
    }
    &-thread-detail {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      margin-bottom: 3rem;
    }
    &-tabs {
      display: flex;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      z-index: 9940;
    }
    &-nav-bubble {
      top: -13px;
      transform: translateX(-50%);
      left: 50%;
      &-sidebar {
        right: -25px;
        top: 50%;
        left: auto;
        transform: translate(-50%,-50%);
      }
      &-thread {
        left: -10px;
        transform: translate(0%, -50%);
        top: 50%;
      }
    }
    &-nav-link {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      &-filter {
        display: block;
        padding-top: .3rem;
        padding-bottom: .3rem;
      }
    }
  }
  #wrapCompose {
    width: 60%;
  }
}

.nav-recipient {
  .nav-link {
    &.active {
      background: #78A4FE;
      border-radius: 25px;
    }
  }
}
