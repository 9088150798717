.appUser {
  &-image {
    padding-right: 1.25rem;
    .rounded-circle {
      box-shadow: 0 0 8px rgba($blue, 0.9);
      border: 2px solid #fff;
      width: 64px;
      height: 64px;
    }
  }
  &-title {
    font-size: 1.25rem;
    color: theme-color("primary");
    margin: 0;
    word-break: break-all;
  }
  &-subtitle {
    margin: 0;
    color: $blue;
    line-height: 1;
    font-weight: 200;
    font-size: 1.1rem;
  }
  &-action {
    font-size: 1rem;
    margin: 0.3125rem 0 0 0;
    line-height: 1.1;
    font-weight: 200;
  }
  &-text {
    color: $gray-600;
    margin: 0;
    line-height: 1;
    font-size: 1rem;
  }
  &-header {
    animation: user-show .5s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards;
    animation-delay: .3s;
    opacity: 0;
  }
}
.appPatientCard {
  &-animate {
    animation: patient-show .5s cubic-bezier(0.175,   0.885, 0.320, 1.275) forwards;
    opacity: 0;
  }
}
.userBar {
  background: #000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}