.modal {
  &.fade .modal-dialog {
    transform: translate(400px, 0) scale(0.7);
  }
  &.show .modal-dialog, &.in .modal-dialog {
    transform: translate(0, 0) scale(1);
  }
  &.fade .modal-delete {
    transform: translate(0, 0) scale(0.5);
  }
  &.show .modal-delete, &.in .modal-delete {
    transform: translate(0, 0) scale(1);
  }
  &.in {
    opacity: 1;
  }
  z-index: 9990;
}
.modal-dialog {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
/*
@include media-breakpoint-up(sm) {
  .modal-dialog {
    margin: 0;
    float: right;
  }
  .modal-delete {
    float: none;
    margin: 0 auto;
    width: 350px;
  }
  .modal-basic.modal-dialog {
      width: 40%;
  }
}
*/
.modal-content {
  box-shadow: 0 0 8px rgba($blue, 0.3);
}
