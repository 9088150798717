//Bootstrap
@import "~bootstrap/scss/functions";
@import "_vars";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "modules/bootstrap/_background-variant";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

//Autocomplete


@import "~@mobiscroll/react/dist/css/mobiscroll";

@import '~clockpicker-umd/dist/bootstrap-clockpicker';


//Calendar
@import '~@fullcalendar/core/main';
@import '~@fullcalendar/daygrid/main';
@import '~@fullcalendar/timegrid/main';
@import '~@fullcalendar/list/main';
@import '~@fullcalendar/bootstrap/main';

@import '~react-date-range/dist/styles'; // main css file
@import '~react-date-range/dist/theme/default'; // theme css file

//Select2
@import "~select2/src/scss/core";

//Selectize
//@import "~selectize/dist/css/selectize";

//Pace
@import "~pace-progress/themes/blue/pace-theme-minimal";

//Font awesome
@import "~@fortawesome/fontawesome-free/css/all";

//Intro.js
@import "~intro.js/introjs";
@import "~intro.js/themes/introjs-modern";

//Datetime
@import "~pickadate/lib/themes/default";
@import "~pickadate/lib/themes/default.date";
@import "~pickadate/lib/themes/default.time";

//Summertime
@import "~summernote/dist/summernote-bs4";

//Offline
@import "~offline-js/themes/offline-theme-slide";
@import "~offline-js/themes/offline-language-czech";

//FilePond
@import "~filepond/dist/filepond.min";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview";

//Bootstrap modules
@import "modules/bootstrap/nav";
@import "modules/bootstrap/card";
@import "modules/bootstrap/modal";
@import "modules/bootstrap/breadcrumb";
@import "modules/bootstrap/tooltip";
@import "modules/bootstrap/tables";
@import "modules/bootstrap/forms";
@import "modules/bootstrap/dropdown";
@import "modules/bootstrap/buttons";

//Vizit modules
@import "modules/vizit/body";
@import "modules/vizit/header";
@import "modules/vizit/breadcrumb";
@import "modules/vizit/vizit";
@import "modules/vizit/user";
@import "modules/vizit/patient";
@import "modules/vizit/login";
@import "modules/vizit/navigation";
@import "modules/vizit/order";
@import "modules/vizit/chart";
@import "modules/vizit/sidebar";
@import "modules/vizit/panel";

//UI modules
@import "modules/ui/pace";
@import "modules/ui/autocomplete";
@import "modules/ui/mm";
@import "modules/ui/pdf";
@import "modules/ui/tutorial";
@import "modules/ui/currentStatus";
@import "modules/ui/protocol";
@import "modules/ui/load";
@import "modules/ui/loader";
@import "modules/ui/datetime";
@import "modules/ui/offline";
@import "modules/ui/widget";
@import "modules/ui/upload";
@import "modules/ui/contentPlaceholder";
@import "modules/ui/alert";
@import "modules/ui/progressBar";
@import "modules/ui/wysiwyg";
@import "modules/ui/animations";
@import "modules/ui/calendar";

