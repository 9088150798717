#alert-container {
    position: fixed;
    top: 0.625rem;
    left: 50%;
    transform: translateX(-50%);
    backface-visibility: hidden;
    width: 75%;
    z-index: 2147483647;
    .alert {
        width: 74px;
        height: 74px;
        animation: fadeInCircle .3s ease forwards, resizeCircle .3s .4s cubic-bezier(.25, .25, .4, 1.6) forwards;
        -webkit-animation: fadeInCircle .3s ease forwards, resizeCircle .3s .4s cubic-bezier(.25, .25, .4, 1.6) forwards;
        overflow: hidden;
        transform: translateZ(0);
        backface-visibility: hidden;
        position: relative;
        border-radius: 40px;
        padding: 1rem;
        &-image {
            margin-right: .5rem;
            width: 42px;
            height: 42px;
        }
        &-body {
            opacity: 0;
            animation: fadeIn .3s .5s ease forwards;
            -webkit-animation: fadeIn .3s .5s ease forwards;
            backface-visibility: hidden;
            position: relative;
            z-index: 90;
            padding-right: 1rem;
            animation-delay: .3s;
        }
        &-dismissible-absolute {
            .close {
                position: absolute;
                top: .1rem;
                z-index: 100;
                right: .1rem;
                padding: 0.75rem 1.25rem;
                color: inherit;
                opacity: 0;
                animation: fadeIn .3s .5s ease forwards;
                -webkit-animation: fadeIn .3s .5s ease forwards;
                animation-delay: .5s;
            }
        }
    }
}
.alert-error {
    @include alert-variant(theme-color("danger"), theme-color("danger"), #fff);
}
.alert-notice {
    @include alert-variant(theme-color("warning"), theme-color("warning"), #000);
}
.alert--ie {
    position: fixed;
    left: 0;
    display: table;
    vertical-align: middle;
    top: 0;
    background: $red;
    color: #fff;
    width: 100%;
    z-index: 2147483647;
    height: 100%;
    .alert__content {
        height: 100%;
        width: 100%;
        font-size: 30px;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
    }
}

.alert {
    .card-title {
        text-transform: uppercase;
    }
}
