.v-navigation {
    background: #fff;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    box-shadow: 0 10px 30px rgba($blue, 0.3);
    //z-index: 9900;
    min-height: 58px;
    //position: fixed;
    top: 0;
    left: 0px;
    margin-bottom: 0;
    .v-buttonGroup {
      &Collapsed {
      position: fixed;
      background: #fff;
      display: none;
      padding: 10px;
      justify-content: center;
      top: 58px;
      left: 0;
      right: 0;
      z-index: 9977;
      .btn-group {
        flex-direction: column;
        width: 100%;
      }
      }
      &.active {
        display: flex;
      }
    }
}
@include media-breakpoint-up(md) {
  .v-navigation {
    justify-content: space-between;
    background: rgba(#fff, .7);
    margin-bottom: 20px;
    .v-buttonGroup {
      background: transparent;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      padding: 0;
      display: flex;
      .btn-group {
        flex-direction: row;
        width: auto;
      }
    }
    .v-buttonCollapse {
      display: none;
    }
  }
}
