.v-chart {
    &Title {
        font-size: 40px;
        color: $purple;
        small {
            font-size: 16px;
            color: $gray-900;
            font-weight: 700;
        }
    }
    &Date {
        font-size: 14px;
        color: $gray-600;
    }
}