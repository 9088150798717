.upload {
    position: relative;
    margin-bottom: 1.875rem;
    &-remove {
        position: absolute;
        bottom: -20px;
        left: 50%;
        width: 3.125rem;
        height: 3.125rem;
        line-height: 2;
        border-radius: 50%;
        color: #fff;
        margin-left: -1.5625rem;
        background: $red;
        display: inline-block;
        font-size: 1.25rem;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 5px solid #fff;
        
        @include transition(.5s);

        &:focus, &:active, &:hover {
            text-decoration: none;
            background: darken($red,10);
            color: #fff;
        }
    }
}