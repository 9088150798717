.appHeader {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	//border-bottom: 0.0625rem solid $purple;
	margin-bottom: 0.625rem;
	position: relative;
	z-index: 1050;
}
.customerHeader {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	background: #fff;
	box-shadow: 0 10px 30px rgba($blue,.3);
	right: 0;
	padding: 1rem;
	text-transform: uppercase;
	z-index: 999;
	align-items: center;
	justify-content: center;
	img {
		margin-right: 1rem;
	}
	font-size: 1.2rem;
	font-weight: 700;
	transition: .5s;
	transform: translateY(-100%);
	&.active {
		transform: translateY(0%);
	}
}
@include media-breakpoint-up(lg) {
	.customerHeader {
		display: flex;
	}
}