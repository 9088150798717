.v-login {
    height: 100vh;
}
.v-loginForm {
    position: relative;
    z-index: 2;
    transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    transform: translateY(100px);
    &-loader {
        position: absolute;
        top: 200px;
        left: 50%;
        z-index: 999;
        transform: translate(-50%,-50%);
    }
    &-fadeOut {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }
    &-fadeIn {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
    &-success {
        background: $green-light;
    }
    &-error {
        background: $red-light;
    }
    &-active {
        opacity: 1;
        transform: translateY(0);
    }
}

.loader-circle {
    fill: theme-color("primary");
    animation: rotating 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    transform-origin: 50% 50%;
    opacity: 0;
    transition: opacity .5s;
}
.loader-check {
    opacity: 0;
    fill: none;
    stroke: theme-color("success");
    stroke-width: 2;
    stroke-dasharray: 20;
    stroke-dashoffset: 20;
    &-active {
        opacity: 1;
        animation: dash .7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
}
.loader-cross {
    fill: theme-color("danger");
    opacity: 0;
    &-active {
        transform-origin: 50% 50%;
        animation: cross-show .7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
}
