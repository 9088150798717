#autoComplete_wrapper {
  position: relative;
  display: inline-block;
}
.autoComplete-component {
  position: relative;
}
.autoComplete {
  @extend .form-control;
  position: relative;
}
.form-groupped .autoComplete {
  padding-bottom: 0;
}
#autoComplete_list {
  position: absolute;
  z-index: 1000;
  padding: 0;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  top: 38px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all -webkit-transform 0.1s ease;
}

.autoComplete_result {
  padding: 0.6rem;
  max-width: 100%;
  border: 0.05rem solid #e3e3e3;
  list-style: none;
  text-align: left;
  font-size: 1.1rem;
  color: rgb(123, 123, 123);
  transition: all 0.1s ease-in-out;
  background-color: #fff;
}

.autoComplete_result::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_result:last-child {
  border-radius: 0 0 1rem 1rem;
}

.autoComplete_result:hover {
  cursor: pointer;
  background-color: rgba(255, 248, 248, 0.9);
  border-left: 2px solid $blue;
  border-right: 2px solid $blue;
  //border-top: 2px solid transparent;
  //border-bottom: 2px solid transparent;
}

.autoComplete_result:focus {
  outline: none;
  background-color: rgba(255, 248, 248, 0.9);
  border-left: 2px solid $blue;
  border-right: 2px solid $blue;
  //border-top: 2px solid transparent;
  //border-bottom: 2px solid transparent;
}

.autoComplete_highlighted {
  opacity: 1;
  color: $blue;
  font-weight: bold;
}

.autoComplete_highlighted::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_selected {
  cursor: pointer;
  background-color: rgba(255, 248, 248, 0.9);
  border-left: 2px solid $blue;
  border-right: 2px solid $blue;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

@media only screen and (max-width: 600px) {
  #autoComplete {
    width: 5rem;
  }

  #autoComplete:focus {
    width: 11rem;
    background-size: 1.6rem;
    background-position: left 1.1rem top 0.75rem;
  }

  .autoComplete_result:first-child {
    border-radius: 1rem 1rem 0 0;
  }

  .autoComplete_result:last-child {
    border-radius: 0 0 1rem 1rem;
  }

  .autoComplete_result:only-child {
    border-radius: 1rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #autoComplete {
    border-width: 1px;
  }

  #autoComplete:hover {
    border-width: 1px;
  }

  #autoComplete:focus {
    border-width: 1px;
  }
}

@-moz-document url-prefix() {
  #autoComplete {
    border-width: 1px;
    background-size: 1.2rem;
    background-origin: border-box;
    background-position: center;
  }

  #autoComplete:hover {
    border-width: 1px;
  }

  #autoComplete:focus {
    border-width: 1px;
    background-position: left 1.1rem top 0.8rem;
  }
}


.react-autosuggest__container {
  position: relative;
  &:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: $gray-600;
    content: '\f002';
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 14px;
    z-index: 10;
  }
}


.react-autosuggest__input {
  @extend .form-control;
}
.react-autosuggest__wrapper {
  position: relative;
}
.react-autosuggest__close {
  @extend .btn;
  position: absolute;
  right: 5px;
  top: 3px;
  background: #fff;
  z-index: 99;
  padding-top: 3px;
  padding-bottom: 3px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid $purple;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;

}

.react-autosuggest__suggestion--highlighted {
  background-color: lighten(#64cad4,10%);
  //color: #fff;
}
.react-autosuggest__suggestion-title {
  font-weight: bold;
  &.highlight {
    //color: $pink;
  }
}
.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}
.react-autosuggest__suggestion-specialization {
  display: block;
  border-top: 1px solid #ccc;
  padding-top: 3px;
}
.componentAutocomplete {
  position: relative;
}
.componentAutocomplete[required]:before {
    color: theme-color("danger");
    content: "*";
    z-index: 1;
    font-family: arial;
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 9px;
}