/*
.note-toolbar {
    background: $cyan;
}
.note-btn {
    background: transparent;
    border: none;
    color: #fff;
    &:hover,&:active,&.active,&.dropdown-toggle {
        border: none !important; 
        background: darken($cyan, 10%) !important;
    }
}
.note-frame * {
    color: #fff;
}
.note-editor.note-frame .note-editing-area .note-editable *{
    color: #000;
}
.note-editor.note-frame {
    border: none;
}
.note-btn-group .dropdown-menu * {
    color: #000;
}
.note-editor .note-editable p { margin-bottom: 0 !important; }
*/
.note-editor.note-frame {
    border: none;
}
.variables-body {
    height: 400px;
    overflow-y: auto;
}