.currentStatus {
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0px;
    background: #fff;
    z-index: 10;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 30px rgba($blue, 0.3);
    &-body {
        padding: .5rem 1rem;
        display: flex;
        width: 100%;
        //align-items: center;
        //justify-content: space-between;
    }
    &-tags {
        display: flex;
        width: 100%;
        align-items: center;
    }
    .badge .badge {
        font-size: .7rem;
    }
    &-divider {
        width: 1px;
        height: 40px;
        background: #ccc;
        display: block;
    }
}
@include media-breakpoint-up(lg) {
    .currentStatus {
        left: 80px;
        width: calc(100% - 80px);
    }
}