.v-vizit {
    @extend .card;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    &Body {
        @extend .card-body;
    }
    &Controls {
        @extend .card-controls;
        padding-bottom: 20px;
    }
    &Examination {
        @extend .card-body;
        background: linear-gradient(to right, lighten($cyan,20%) 0%, $cyan 100%);
        color: #fff !important;
        border-radius: 0 $card-border-radius $card-border-radius 0;
        .v-vizitControls {
            display: flex;
            justify-content: flex-end;
        }
        a, .btn {
            color: #fff;
        }
    }
    
    &Title {
        @extend .card-title;
    }
    &Date {
        font-size: 16px;
        font-weight: 700;
    }
}
@include media-breakpoint-up(lg) {
    .v-vizit {
        flex-direction: column;
    }
}
@include media-breakpoint-up(xl) {
    .v-vizit {
        flex-direction: row;
        &Body {
            width: 60%;
        }
        &Examination {
            width: 40%;
        }
    }
}
.worklist-list {
    @include transition($transition-fade);
    &:not(.show) {
        display: none;
        opacity: 0;
    }   
}
.worklist-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
}
@include media-breakpoint-up(xl) {
    .worklist-wrap {
        display: flex;
    }
    .worklist-detail {
        margin-left: 2rem;
        width: 100%;
    }
    .worklist-button {
        display: none;
    }
    .worklist-list {
        width: 350px;
        &:not(.show) {
            display: block;
            opacity: 1;
        }
    }
}