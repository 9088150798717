.table {
	border-color: theme-color('primary');
	th,
	td {
		color: $indigo;
		font-weight: 300;
		strong {
			font-weight: bold;
		}
		//word-break: break-all;
	}
}
.table-cards {
	border-spacing: 0 10px;
	border-collapse: separate;
	th,
	td {
		border-top: 0;
		font-weight: 300;
		font-size: .9rem;
	}
	tbody .table-blank {
		width: 10px;
		background: #fff;
		@include transition(0.5s);
	}
	thead th {
		color: theme-color('primary');
		font-weight: 500;
		border-bottom: 0;
	}

	tbody + tbody {
		border-top: 0;
	}
	tbody td {
		background: #fff;
		color: $indigo;
	}
	tbody tr {
		box-shadow: 0 10px 30px rgba($blue,.3);
		position: relative;
	}
	tbody tr td:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	tbody tr td:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.table {
		background-color: $body-bg;
	}
	.td-controls {
		text-align: right;	
	}
	th {
		.custom-control-label::before {
			background-color: #fff;
		}
	}
}
.table-patient {
	th,td {
		color: #000;
		font-size: 14px;
		strong {
			font-weight: bold;
		}
	}
	td {
		word-break: normal;
	}
	th {
		white-space: nowrap;
	}
	@extend .table-sm;
	@extend .table-borderless;
}
.table-white {
	th,td {
		color: #fff;
	}
}
@include media-breakpoint-down(md) {
	.table-responsive,.table--responsive {
		thead {
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
		tr {
			border-bottom: 1px solid #ddd;
			display: block;
			margin-bottom: 30px;
		}
		td {
			display: block;
			text-align: right;
		}
		td:before {
			/*
			* aria-label has no advantage, it won't be read inside a table
			content: attr(aria-label);
			*/
			content: attr(data-label);
			float: left;
			font-weight: bold;
			text-transform: uppercase;
		}
		td:last-child {
			border-bottom: 0;
		}
	}
	.table-responsiveLeft {
		td {
			text-align: left;
		}
	}
}
