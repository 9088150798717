.v-panel-open {
    // Kill the scroll on the body
    overflow: hidden;
    .v-panel {
      overflow-x: hidden;
      overflow-y: auto;
    }
}
// Container that the modal scrolls within
.v-panel {
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 9800;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
    box-shadow: 0 10px 30px rgba(64, 42, 150, 0.3);
    background: rgba($black, .4);
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695
  }
  
  // Shell div to position the modal with bottom padding
  .v-panel-container {
    position: relative;
    width: auto;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;
  
    // When fading in the modal, animate it to slide down
    .v-panel.fade & {
      transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateY(-20%) scale(0);
    }
    .v-panel.show & {
      transform: translateY(0%) scale(1);
    }
    
    .v-panel.worklist & {
      transition: transform .5s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateX(100%);
      
    }
    .v-panel.show-worklist & {
      transform: translateX(0%);
      width: calc( 100% - 350px);
      left: 350px;
      box-shadow: 0 0 40px rgba($purple, 0.4);
    }
  }
  @include media-breakpoint-up(lg) {
    .v-panel {
      left: 80px;
      width: calc( 100% - 80px);
    }
  }
  // Actual modal
.v-panel-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    min-height: 100vh;
    color: #444;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: 0;
  }
  .v-panel-header {
    display: flex;
    align-items: flex-start; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    padding: 1rem;
    font-weight: bold;
    font-size: 1.4rem;
    .close {
      padding: $modal-header-padding;
      // auto on the left force icon to the right even when there is no .modal-title
      margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
      font-size: 2.5rem;
      color: $purple;
      transition: .5s;
      &:hover, &:active, &:focus {
        color: darken($purple, 20%);
      }
    }
  }
  .v-panel-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when there should be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  // Footer (for actions)
.v-panel-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: $modal-inner-padding - $modal-footer-margin-between / 2;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    //@include border-bottom-radius($modal-content-inner-border-radius);
  
    // Place margin between footer elements
    // This solution is far from ideal because of the universal selector usage,
    // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
    // stylelint-disable-next-line selector-max-universal
    > * {
      margin: $modal-footer-margin-between / 2;
    }
  }